import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import "./src/styles/reset.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"
import { sendShopifyAnalytics, getClientBrowserParameters, AnalyticsEventName } from '@shopify/hydrogen-react'


const rIC = typeof requestIdleCallback !== "undefined" ? requestIdleCallback : setTimeout

const sendPageView = (pageType) => {
  const analyticsPageData = {
    shopId: 'gid://shopify/Shop/82316067107',
    currency: 'SGD',
    acceptedLanguage: 'en',
    pageType: pageType,
  };

  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  };

  // console.log("Payload for Shopify analytics:", payload);

  sendShopifyAnalytics({
    eventName: AnalyticsEventName.PAGE_VIEW,
    payload,
  });

  // console.log("Page view sent to Shopify analytics");
};

export const onInitialClientRender = () => {
  // console.log("Initial client render occurred");
  // Assign a default pageType or specify one based on your requirements
  const pageType = 'home'; // Example: Sending 'home' as the default pageType for the initial render
  setTimeout(() => {
    sendPageView(pageType);
  }, 1000);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("Route updated. Location:", location.pathname);
  
  let pageType = 'unknown'; // Default to 'unknown' if no conditions match
  
  if (location.pathname.startsWith('/news/') || location.pathname.startsWith('/events/')) {
    pageType = 'blog'; // News-related pages
  } else if (location.pathname.startsWith('/artists/')) {
    pageType = 'collection'; // Artists-related pages
  } else if (location.pathname.startsWith('/advisory/products') || location.pathname.startsWith('/advisory-hidden/') || location.pathname.startsWith('/gallery-ecommerce-checkout/') || location.pathname.startsWith('/gallery/') || location.pathname.startsWith('/products/') || location.pathname.startsWith('/ecommerce/')) {
    pageType = 'product'; // Advisory or Gallery pages
  } else if (location.pathname.startsWith('/cart/')) {
    pageType = 'cart'; // Cart page
  } else if (location.pathname.startsWith('/login/')) {
  pageType = 'password';
  } else if (location.pathname.startsWith('/404/')) {
    pageType = 'notFound';
  } else {
    pageType = 'page';
  }

  // console.log("Determined pageType:", pageType); // Debugging statement

  rIC(() => {
    sendPageView(pageType);
    if (!location.hash.includes('#visit')) {
      window.scrollTo(0, 0);
    }
  });

  // Example usage of prevLocation
  if (prevLocation) {
    // console.log("Previous location:", prevLocation.pathname);
    // Perform actions based on the previous location if needed
  }
}

/*export const shouldUpdateScroll = () => {
  // Always scroll to the top
  return false;
};*/
export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    {element}
    {/* <useShopifyCookies hasUserConsent={false} /> */}
  </StoreProvider>
)

// export const onRenderBody = ({ setHtmlAttributes }) => {
//   setHtmlAttributes({ lang: "en" });
// };
